<template>
  <div class="register container h-100 mb-4">
    <div class="row h-100 align-items-center pt-4 pb-4">
      <div class="col-12 col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">
        <div class="card shadow sm m-0 p-0">
          <div class="card-body m-0 p-0">
            <div class="d-block w-100 register-header p-0 m-0">
              <h5 class="text-center letter-spacing-2">Register</h5>
            </div>
            <form action="javascript:void(0)" @submit="register" class="row form-register label-top text-start" enctype="multipart/form-data" method="post">
<!--              <div class="form-group col-12 ps-3 pe-3 pb-2">-->
<!--                <label class="font-weight-bold">Account Type</label> <span class="required">*</span>-->
<!--                <select-->
<!--                    v-model="user.contact_type_id"-->
<!--                    class="form-select select-placeholder"-->
<!--                    @focus="handleFocus($event)"-->
<!--                    @focusout="handleFocusOut(user.contact_type_id, $event)"-->
<!--                    tabindex="-1"-->
<!--                    aria-label="contactTypeId"-->
<!--                    name="contactTypeId"-->
<!--                    id="contactTypeId"-->
<!--                >-->
<!--                  <option value="" disabled>Select account type</option>-->
<!--                  <option v-for="(item , index) in contactTypeOptions" v-bind:key="index" :value="item.id" >-->
<!--                    {{item.type}}-->
<!--                  </option>-->
<!--                </select>-->
<!--                <p v-if="this.validationErrors && this.validationErrors.contact_type_id" class="required-info text-center">-->
<!--                  {{ this.validationErrors.contact_type_id[0] }}-->
<!--                </p>-->
<!--              </div>-->
              <div class="form-group col-12 pb-2">
                <div class="row m-0 p-0">
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-3 col-md-3 col-lg-3 p-1 m-0 mt-3">
                    <select required
                        v-model="user.title"
                        class="form-select select-placeholder top cursor-pointer"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(user.title, $event)"
                        tabindex="-1"
                        aria-label="title"
                        name="title"
                        id="title"
                    >
                      <option value="" disabled></option>
                      <option v-for="(item , index) in titleOptions" v-bind:key="index" :value="item.text" >
                        {{item.text}}
                      </option>
                    </select>
                    <label class="font-weight-bold top">Title <span class="required"> *</span></label>
                  </div>
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 p-1 m-0 mt-3">
                    <input required type="text" name="name" maxlength="50" v-model="user.name" id="name" placeholder=" " class="form-control top"> <!-- placeholder="Enter name" -->
                    <label for="name" class="font-weight-bold top">Name <span class="required"> *</span></label>
                  </div>
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-5 col-md-5 col-lg-5 p-1 m-0 mt-3">
                    <input required type="text" name="surname" maxlength="50" v-model="user.surname" id="surname" placeholder=" " class="form-control top"> <!-- placeholder="Enter surname" -->
                    <label for="surname" class="font-weight-bold top">Surname <span class="required"> *</span></label>
                  </div>
                </div>
                <p v-if="this.validationErrors && this.validationErrors.title" class="required-info text-center">
                  {{ this.validationErrors.title[0] }}
                </p>
                <p v-if="this.validationErrors && this.validationErrors.name" class="required-info text-center">
                  {{ this.validationErrors.name[0] }}
                </p>
                <p v-if="this.validationErrors && this.validationErrors.surname" class="required-info text-center">
                  {{ this.validationErrors.surname[0] }}
                </p>
              </div>

              <!-- Upload User Image -->
              <div class="row m-0 p-0 text-center">
                <!-- User Image Upload -->
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <div class="col-12">
<!--                      <p class="pb-2">Profile Image | Left: <span style="color: red;">{{ calculateAllowedImages(user.previewUserImage, null, 1) }}</span></p>-->
                      <p class="pb-2">Profile Image | Uploaded: <span style="color: red;">{{ calculateImages(user.previewUserImage, null) }} / 1</span></p>
                      <div class="dropbox">
                        <input type="file" accept="image/*" @change="uploadUserImage" name="previewUserImage" id="userImage" class="input-file">
                        <p>Click on the box or drop the image into the box in order to upload it (Max file size: 2MB)</p>
                        <div class="row p-0 m-0 mb-2">
<!--                          <div v-for="image in user.previewUserImage" class="col-4 p-1 mt-1 mb-1">-->
<!--                            <img v-if="image" :src="image" class="uploading-user-image" />-->
<!--                          </div>-->
                          <img v-if="user.previewUserImage && user.previewUserImage.length > 0" :src="user.previewUserImage[0]" class="uploading-user-image img-fluid d-block" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--
                    It would also be better to display name of the image rather than some random number..
                    not sure how to achieve that though
                  -->
                  <div v-for="(error, key, index) in this.validationErrors">
                    <p v-if="key.includes('userImage')" class="required-info text-center">
                      Image number {{ Number(key.substr(key.indexOf('.') + 1)) + 1 }} - {{ error[0] }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- End Upload User Image -->

<!--              <div class="form-group col-12 pb-2">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="form-group col-8 p-1 m-0">-->
<!--                    <label for="company_name" class="font-weight-bold">Company name</label>-->
<!--                    <input type="text" name="company_name" maxlength="100" v-model="user.company_name" id="company_name" placeholder="Enter company name" class="form-control">-->
<!--                    &lt;!&ndash;                    <small>Note that mobile is the number that will be displayed on your advertising.</small>&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="form-group col-4 p-1 m-0">-->
<!--                    <label for="landing" class="font-weight-bold">Show company name</label><br/>-->
<!--                    <label class="switch mt-2">-->
<!--                      <input type="checkbox" v-model="user.show_company_name">-->
<!--                      <span class="slider round"></span>-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              &lt;!&ndash; Address &ndash;&gt;-->
<!--              <div class="col-12 ps-3 pe-3 pb-2">-->
<!--                <div class="row m-0 p-0">-->
<!--                  &lt;!&ndash; Should be col-8, for time being col-12 as we do not do manual address enter &ndash;&gt;-->
<!--                  <div class="col-8 m-0 p-0">-->
<!--                    <label class="font-weight-bold">Company Address</label>-->
<!--                    <div id="geocoderRegister"></div>-->
<!--                  </div>-->
<!--                  <div class="form-group col-4 p-1 m-0">-->
<!--                    <label for="landing" class="font-weight-bold">Show address</label><br/>-->
<!--                    <label class="switch mt-2">-->
<!--                      <input type="checkbox" v-model="user.show_address">-->
<!--                      <span class="slider round"></span>-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

              <!-- Start Horizontal Line with Icon -->
              <div class="horizontal-separator-box">
                <span class="hr-line-short"></span>
                <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                <span class="hr-line-short"></span>
              </div>
              <!-- End Horizontal Line with Icon -->

              <!-- Information -->
              <small class="p-3 text-center">Note that you need to provide mobile number in order to verify account.</small>

              <!-- Start Horizontal Line with Icon -->
              <div class="horizontal-separator-box">
                <span class="hr-line-short"></span>
                <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                <span class="hr-line-short"></span>
              </div>
              <!-- End Horizontal Line with Icon -->

              <!-- Start Recaptcha Verification Container -->
              <div id="recaptcha-container"></div>
              <!-- End Recaptcha Verification Container -->

              <div class="form-group col-12 pt-2 pb-3">
                <div class="row m-0 p-0 min-height-60">
                  <span data-tooltip="tooltip" class="info-tooltip">
                    <div class="no-text-wrap overflow-hidden text-truncate">
                      <font-awesome-icon icon="info-circle" class="icon-fa" style="color: orange;" />
                      <span class="tooltiptext">Prefix example: 44 or +44 for the UK</span>
                    </div>
                  </span>
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-2 col-md-2 col-lg-2 p-1 m-0 mt-3">
                    <input required type="number" name="mobile-prefix" minlength="1" maxlength="7" v-model="user.mobile_prefix" id="mobile-prefix" placeholder=" " class="form-control top" style="padding: 0.375rem 0.95rem;"> <!-- placeholder="+" -->
                    <label for="mobile-prefix" class="font-weight-bold top">Prefix <span class="required"> *</span></label>
                  </div>
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 p-1 m-0 mt-3">
                    <input required type="number" name="mobile" minlength="4" maxlength="15" v-model="user.mobile" id="mobile" placeholder=" " class="form-control top"> <!-- placeholder="Enter mobile number" -->
                    <label for="mobile" class="font-weight-bold top">Mobile <span class="required"> *</span></label>
                  </div>
                  <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 m-0 mt-3">
                    <button type="button" @click="sendCode()" :disabled="processing" :class="[user.codeSent ? 'btn btn-outline-success black-text me-2' : 'btn btn-outline-warning black-text me-2']">
                      {{ processing ? "Please wait" : "Send Code" }}
                    </button>
                    <div v-if="processing" class="text-center d-inline-block">
                      <div class="spinner-border text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 m-0 pt-2 mt-3 position-relative cursor-pointer">
                    <span data-tooltip="tooltip">
                      <div class="no-text-wrap overflow-hidden text-truncate">
                        <p v-if="user.codeSent && user.mobile == mobileVerified && user.mobile_prefix == prefixVerified"><font-awesome-icon icon="check-circle" class="icon-fa price-color" /></p>
                        <p v-else><font-awesome-icon icon="times-circle" class="icon-fa required" /></p>
                        <span v-if="user.codeSent && user.mobile == mobileVerified && user.mobile_prefix == prefixVerified" class="tooltiptext">Status: Code Sent</span>
                        <span v-else class="tooltiptext">Status: Code Not Sent</span>
                      </div>
                    </span>
                  </div>

<!--                  <div class="form-group col-4 p-1 m-0">-->
<!--                    <label for="landing" class="font-weight-bold">Show mobile number</label><br/>-->
<!--                    <label class="switch mt-2">-->
<!--                      <input type="checkbox" v-model="user.show_mobile">-->
<!--                      <span class="slider round"></span>-->
<!--                    </label>-->
<!--                  </div>-->
                </div>
                <p v-if="this.sendCodeError" class="required-info text-center">
                  {{ this.sendCodeError }}
                </p>
                <p v-if="this.validationErrors && this.validationErrors.mobile_prefix" class="required-info text-center">
                  {{ this.validationErrors.mobile_prefix[0] }}
                </p>
                <p v-if="this.validationErrors && this.validationErrors.mobile" class="required-info text-center">
                  {{ this.validationErrors.mobile[0] }}
                </p>
<!--                <p v-if="this.validationErrors && this.validationErrors.show_mobile" class="required-info text-center">-->
<!--                  {{ this.validationErrors.show_mobile[0] }}-->
<!--                </p>-->
              </div>

<!--              &lt;!&ndash; Recaptcha Verification Container &ndash;&gt;-->
<!--              <div class="form-group col-12 pb-2">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="form-group col-6 p-1 m-0">-->
<!--                    <div id="recaptcha-container"></div>-->
<!--                  </div>-->
<!--                  <div class="form-group col-6 p-1 m-0 align-content-center align-items-center">-->
<!--                    <button type="button" @click="sendCode()" :disabled="processing" class="btn btn-primary btn-block">-->
<!--                      {{ processing ? "Please wait" : "Send Code" }}-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

              <div class="form-group col-12 pb-3">
                <div class="row m-0 p-0 min-height-60">
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-1 m-0 mt-3">
                    <input required type="text" name="verificationCode" v-model="user.verificationCode" id="code" placeholder=" " class="form-control top"> <!-- placeholder="Enter verification code" -->
                    <label for="code" class="font-weight-bold top">Verification Code <span class="required"> *</span></label>
                  </div>
                  <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 m-0 mt-3">
                    <button type="button" @click="verifyCode()" :disabled="processing" :class="[user.codeVerified ? 'btn btn-outline-success black-text me-2' : 'btn btn-outline-warning black-text me-2']">
                      {{ processing ? "Please wait" : "Verify Code" }}
                    </button>
                    <div v-if="processing" class="text-center d-inline-block">
                      <div class="spinner-border text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 m-0 pt-2 mt-3 position-relative cursor-pointer">
                    <span data-tooltip="tooltip">
                      <div class="no-text-wrap overflow-hidden text-truncate">
                        <p v-if="user.codeVerified && user.mobile == mobileVerified && user.mobile_prefix == prefixVerified"><font-awesome-icon icon="check-circle" class="icon-fa price-color" /></p>
                        <p v-else><font-awesome-icon icon="times-circle" class="icon-fa required" /></p>
                        <span v-if="user.codeVerified && user.mobile == mobileVerified && user.mobile_prefix == prefixVerified" class="tooltiptext">Status: Verified</span>
                        <span v-else class="tooltiptext">Status: Not Verified</span>
                      </div>
                    </span>
                  </div>
                </div>
                <p v-if="this.verificationCodeError" class="required-info text-center">
                  {{ this.verificationCodeError }}
                </p>
              </div>

<!--              <div class="form-group col-12 pb-2">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="form-group col-2 p-1 m-0">-->
<!--                    <label for="landing-prefix" class="font-weight-bold">Prefix</label>-->
<!--                    <input type="number" name="landing-prefix" minlength="1" maxlength="7" v-model="user.landing_prefix" id="landing-prefix" placeholder="+" class="form-control">-->
<!--                  </div>-->
<!--                  <div class="form-group col-6 p-1 m-0">-->
<!--                    <label for="landing" class="font-weight-bold">Landing</label>-->
<!--                    <input type="number" name="landing" minlength="4" maxlength="15" v-model="user.landing" id="landing" placeholder="Enter landing number" class="form-control">-->
<!--                  </div>-->
<!--                  <div class="form-group col-4 p-1 m-0">-->
<!--                    <label for="landing" class="font-weight-bold">Show landing number</label><br/>-->
<!--                    <label class="switch mt-2">-->
<!--                      <input type="checkbox" v-model="user.show_landing">-->
<!--                      <span class="slider round"></span>-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <p v-if="this.validationErrors && this.validationErrors.landing_prefix" class="required-info text-center">-->
<!--                  {{ this.validationErrors.landing_prefix[0] }}-->
<!--                </p>-->
<!--                <p v-if="this.validationErrors && this.validationErrors.landing" class="required-info text-center">-->
<!--                  {{ this.validationErrors.landing[0] }}-->
<!--                </p>-->
<!--                <p v-if="this.validationErrors && this.validationErrors.show_landing" class="required-info text-center">-->
<!--                  {{ this.validationErrors.show_landing[0] }}-->
<!--                </p>-->
<!--              </div>-->

              <!-- Start Horizontal Line with Icon -->
              <div class="horizontal-separator-box">
                <span class="hr-line-short"></span>
                <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                <span class="hr-line-short"></span>
              </div>
              <!-- End Horizontal Line with Icon -->

              <div class="form-group form-floating-label col-12 pt-2 pb-3"> <!-- ps-3 pe-3  -->
                <div class="row m-0 p-0">
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-1 m-0 mt-2">
                    <input required type="text" name="email" maxlength="100" v-model="user.email" id="email" placeholder=" " class="form-control top"> <!-- placeholder="Enter email" -->
                    <label for="email" class="font-weight-bold top">Email <span class="required"> *</span></label>
                  </div>
<!--                  <div class="form-group col-4 p-1 m-0">-->
<!--                    <label class="font-weight-bold">Show email</label><br/>-->
<!--                    <label class="switch mt-2">-->
<!--                      <input type="checkbox" v-model="user.show_email">-->
<!--                      <span class="slider round"></span>-->
<!--                    </label>-->
<!--                  </div>-->
                </div>
                <p v-if="this.validationErrors && this.validationErrors.email" class="required-info text-center">
                  {{ this.validationErrors.email[0] }}
                </p>
<!--                <p v-if="this.validationErrors && this.validationErrors.show_email" class="required-info text-center">-->
<!--                  {{ this.validationErrors.show_email[0] }}-->
<!--                </p>-->
              </div>

              <div class="form-group form-floating-label col-12 pb-3">
                <div class="row m-0 p-0">
                  <span data-tooltip="tooltip" class="info-tooltip">
                    <div class="no-text-wrap overflow-hidden text-truncate">
                      <font-awesome-icon icon="info-circle" class="icon-fa" style="color: orange;" />
                      <span class="tooltiptext">Password should be made of minimum 8 characters</span>
                    </div>
                  </span>
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-1 m-0 mt-3">
                    <input required type="password" name="password" v-model="user.password" id="password" placeholder=" " class="form-control top"> <!-- placeholder="Enter password" -->
                    <label for="password" class="font-weight-bold top">Password <span class="required"> *</span></label>
                  </div>
                </div>
              </div>
              <div class="form-group form-floating-label col-12 pb-3">
                <div class="row m-0 p-0">
                  <div class="form-group form-floating-label col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-1 m-0 mt-3">
                    <input required type="password" name="password_confirmation" v-model="user.password_confirmation" id="password_confirmation" placeholder=" " class="form-control top"> <!-- placeholder="Enter password confirmation" -->
                    <label for="password_confirmation" class="font-weight-bold top">Confirm Password <span class="required"> *</span></label>
                  </div>
                </div>
                <p v-if="this.validationErrors && this.validationErrors.password" class="required-info text-center">
                  {{ this.validationErrors.password[0] }}
                </p>
              </div>

              <div class="form-group col-12 ps-3 pe-3 pb-3">
                <span role="button">
                  <input role="button" type="checkbox" name="termsandconditions" v-model="user.termsandconditions" id="termsandconditions" class="me-2">
                  <label role="button" for="termsandconditions" class="font-weight-bold">
                    Tick to accept our <router-link class="text-decoration-none" to="/terms-and-conditions">Terms and Conditions</router-link> and <router-link class="text-decoration-none" to="/privacy-policy">Privacy Policy</router-link>
                  <span class="required"> *</span>
                  </label>
                </span>
                <p v-if="this.validationErrors && this.validationErrors.termsandconditions" class="required-info text-center">
                  {{ this.validationErrors.termsandconditions[0] }}
                </p>
              </div>

              <div class="col-12 mb-2 text-center">
                <button type="submit" :disabled="processing" class="btn-branding border-branding btn-block min-width-100">
                  {{ processing ? "Please wait" : "Register" }}
                </button>
                <p v-if="this.validationMessage" class="required-info text-center">
                  {{ this.validationMessage }} Please scroll up to see error messages.
                </p>
              </div>
              <div class="col-12 text-center">
                <label>Already have an account? <router-link :to="{name:'login'}">Login Now!</router-link></label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import firebase from 'firebase/compat/app'
import axios from 'axios'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
  name:'register',
  props: ['prefixVerified', 'mobileVerified'],
  data(){
    return {
      recaptchaVerifier: null,
      codeResult: null,
      sendCodeError: '',
      verificationCodeError: '',
      user:{
        // contact_type_id: '',
        title: '',
        name: '',
        surname: '',
        previewUserImage: '',
        userImage: '',
        // company_name: '',
        // show_company_name: false,
        // landing_prefix: '',
        // landing: '',
        // show_landing: false,
        mobile_prefix: '',
        mobile: '',
        verificationCode: '',
        // show_mobile: true,
        email: '',
        show_email: false,
        password: '',
        password_confirmation: '',
        termsandconditions: false,
        codeSent: false,
        codeVerified: false,
        // Address
        // postcode: '',
        // line_2: '',
        // city: '',
        // county: '',
        // country: '',
        // line_1: '',
        // longitude: '',
        // latitude: '',
        // boundary_box: '',
        // full_address: '',
        // show_address: false,
      },
      titleOptions: [
        { value: '1', text: 'Mr' },
        { value: '2', text: 'Mrs' },
        { value: '3', text: 'Miss' },
        { value: '4', text: 'Ms' }
      ],
      // Get contactTypes from db and adjust those, add more options such as Tenant, Lender, Investor, Contractor
      // contactTypeOptions: [],
      processing:false,
      validationErrors: '',
      validationMessage: ''
    }
  },
  async mounted() {
    // Toggle background to be invisible (we want video parallax)
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content-transparent');
      if (main.classList.contains('main-content')) {
        main.classList.remove('main-content');
      }
    }

    // this.getContactTypes();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': () => {
        console.log('reCaptcha Loaded');
      }
    });
    await this.recaptchaVerifier.render();
    // Add geocoder search box to address fields
    // this.initGeocodeBox();
  },
  beforeDestroy() {
    // Toggle back the background to be visible
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content');
      if (main.classList.contains('main-content-transparent')) {
        main.classList.remove('main-content-transparent');
      }
    }
  },
  methods:{
    // getContactTypes: async function() {
    //   let headers = {
    //     'Retry-After': 3
    //   }
    //   return axios.post('api/contact-types',[],{headers: headers}).then(({data})=>{
    //     this.contactTypeOptions = data;
    //   })
    // },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    calculateImages(images = null, editImages = null) {
      // Check how many images added compared to set limit
      let count = 0;
      let countEditImages = 0;

      if (images) {
        count = images.length;
      }
      // check if edit images exists
      if (editImages) {
        countEditImages = editImages.length;
      }

      return count + countEditImages;
    },
    calculateAllowedImages(images, editImages = null, limit) {
      // Check how many images added compared to set limit
      if (images) {
        let count = images.length;
        let countEditImages = 0;

        // check if edit images exists
        if (editImages) {
          countEditImages = editImages.length;
        }
        if (limit - count - countEditImages >= 0) {
          return limit - count - countEditImages;
        } else {
          return 'Exceeded allowed number of images';
        }
        // Return maximum allowed
      } else {
        return limit;
      }
    },
    ...mapActions({
      signIn:'auth/login'
    }),
    sendCode() {
      let that = this;
      let number = null;
      that.processing = true;
      that.sendCodeError = '';
      if (this.user.mobile_prefix && this.user.mobile) {
        if (this.user.mobile_prefix.charAt(0) === '+') {
          number = this.user.mobile_prefix + this.user.mobile;
        } else if (!isNaN(this.user.mobile_prefix)) {
          number = '+' + this.user.mobile_prefix + this.user.mobile;
        }
        // Set props so we can make sure that during registration fields still contain same mobile and prefix numbers
        that.prefixVerified = this.user.mobile_prefix;
        that.mobileVerified = this.user.mobile;
      } else if (this.user.mobile) {
        this.user.mobile_prefix = 44;
        number = '+44' + this.user.mobile;
        // Set props so we can make sure that during registration fields still contain same mobile and prefix numbers
        that.prefixVerified = this.user.mobile_prefix;
        that.mobileVerified = this.user.mobile;
      } else {
        that.sendCodeError = 'Prefix and mobile are required fields';
        that.processing = false;
        return;
      }

      firebase.auth().signInWithPhoneNumber(number, this.recaptchaVerifier).then(function (confirmationResult) {
        that.codeResult = confirmationResult;
        that.sendCodeError = '';
        if (that.validationErrors.mobile_prefix) {
          that.validationErrors.mobile_prefix = '';
        }
        if (that.validationErrors.mobile) {
          that.validationErrors.mobile = '';
        }
        that.user.codeSent = true;
        that.processing = false;
      }).catch(function (error) {
        that.sendCodeError = error.message;
        // Error; SMS not sent
        if (error.code === "auth/too-many-requests") {
          that.sendCodeError = "Unable to verify Captcha at this time. Please try again after some time";
        }
        that.user.codeSent = false;
        that.processing = false;
      });
    },
    verifyCode() {
      let that = this;
      that.processing = true;
      if (this.codeResult) {
        this.codeResult.confirm(this.user.verificationCode).then(function (result) {
          that.verificationCodeError = '';
          that.user.codeVerified = true;
          that.processing = false;
        }).catch(function (error) {
          that.verificationCodeError = error.message;
          that.user.codeVerified = false;
          that.processing = false;
        });
      }
      that.processing = false;
    },
    async register(){
      this.processing = true

      let formData = new FormData();

      // Append all form data, also if statement changes true and false into 0 and 1
      for (let key in this.user) {
        if(this.user[key] === false) {
          this.user[key] = 0;
          // Set show_email or show_mobile empty rather than 0 so that 'required_without' rule in laravel validation can work
          if(key === 'show_email' || key === 'show_mobile') {
            this.user[key] = '';
          }
        } else if(this.user[key] === true) {
          this.user[key] = 1;
        }
        formData.append(key, this.user[key]);
      }
      // Append user image
      if(this.user.userImage.length !== 0) {
        this.user.userImage.forEach((userImage, index) => {
          formData.append("userImage[]", userImage)
        })
      }

      // Loop formData and check if mobile and prefix were not manipulated after verification code was accepted
      for (let pair of formData.entries()) {
        if (pair[0] === 'mobile') {
          if (pair[1] != this.mobileVerified) {
            this.sendCodeError = 'Your prefix or mobile number was changed, it does not match number that was used for verification, either verify number again or reverse changes';
            this.processing = false;
            return;
          }
        }
        if (pair[0] === 'mobile_prefix') {
          if (pair[1] != this.prefixVerified) {
            this.sendCodeError = 'Your prefix or mobile number was changed, it does not match number that was used for verification, either verify number again or reverse changes';
            this.processing = false;
            return;
          }
        }
      }

      await axios.get('/sanctum/csrf-cookie')
      await axios.post('api/register',formData).then(({data})=>{
        this.signIn()
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(()=>{
        this.processing = false
      })
    },
    uploadUserImage(e) {
      this.user.previewUserImage = [];
      this.user.userImage = [];

      let fileList = Array.prototype.slice.call(e.target.files);
      fileList.forEach(f => {

        if(!f.type.match("image.*")) {
          return;
        }

        this.user.userImage.push(f)

        let reader = new FileReader();
        let that = this;

        reader.onload = function (e) {
          that.user.previewUserImage.push(e.target.result);
        }
        reader.readAsDataURL(f);
      });
    },
    // initGeocodeBox() {
    //   this.$nextTick(() => {
    //
    //     // Mapbox Geocoding Address Autocomplete
    //     let geocoder = '';
    //
    //     geocoder = new MapboxGeocoder({
    //       accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
    //       types: 'region,place,district,postcode,locality,address',
    //       limit: 7,
    //       countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
    //     });
    //
    //     let geoCheck = document.getElementById('geocoderRegister');
    //     if (geoCheck) {
    //       if(geoCheck.children.length <= 0) {
    //         geocoder.addTo('#geocoderRegister');
    //       }
    //     }
    //
    //     // Add geocoder result to container.
    //     geocoder.on('result', (e) => {
    //       // console.log(e);
    //       // For districts such as South Yorkshire, Hampshire etc.
    //       if(e.result.place_type.includes('district')) {
    //         for (let i = 0; i < e.result.context.length; i++) {
    //           if (e.result.context[i].id.includes('postcode')) {
    //             this.user.postcode = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('locality')) {
    //             this.user.line_2 = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('place')) {
    //             this.user.city = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('country')) {
    //             this.user.country = e.result.context[i].text;
    //           }
    //         }
    //         // district
    //         if (e.result.text) {
    //           this.user.county = e.result.text;
    //         }
    //         // For places, which are cities, such as Manchester, Leeds etc.
    //       } else if (e.result.place_type.includes('place')) {
    //         for (let i = 0; i < e.result.context.length; i++) {
    //           if (e.result.context[i].id.includes('postcode')) {
    //             this.user.postcode = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('locality')) {
    //             this.user.line_2 = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('district')) {
    //             this.user.county = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('country')) {
    //             this.user.country = e.result.context[i].text;
    //           }
    //         }
    //         // Place - City
    //         if (e.result.text) {
    //           this.user.city = e.result.text;
    //         }
    //       } else if (e.result.place_type.includes('postcode')) {
    //         for(let i=0; i < e.result.context.length; i++) {
    //           if(e.result.context[i].id.includes('locality')) {
    //             this.user.line_2 = e.result.context[i].text;
    //           }
    //           if(e.result.context[i].id.includes('district')) {
    //             this.user.county = e.result.context[i].text;
    //           }
    //           if(e.result.context[i].id.includes('country')) {
    //             this.user.country = e.result.context[i].text;
    //           }
    //         }
    //         // Postcode
    //         if(e.result.text) {
    //           this.user.postcode = e.result.text;
    //         }
    //         // For locality such as Cudworth, Cosham etc.
    //       } else if (e.result.place_type.includes('locality')) {
    //         for (let i = 0; i < e.result.context.length; i++) {
    //           if (e.result.context[i].id.includes('district')) {
    //             this.user.county = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('place')) {
    //             this.user.city = e.result.context[i].text;
    //           }
    //           if (e.result.context[i].id.includes('country')) {
    //             this.user.country = e.result.context[i].text;
    //           }
    //         }
    //         // Locality
    //         if (e.result.text) {
    //           this.user.line_2 = e.result.text;
    //         }
    //         // For addresses, detailed ones such as 7 California Street etc. and all the rest
    //       } else {
    //         for(let i=0; i < e.result.context.length; i++) {
    //           if(e.result.context[i].id.includes('postcode')) {
    //             this.user.postcode = e.result.context[i].text;
    //           }
    //           if(e.result.context[i].id.includes('locality')) {
    //             this.user.line_2 = e.result.context[i].text;
    //           }
    //           if(e.result.context[i].id.includes('place')) {
    //             this.user.city = e.result.context[i].text;
    //           }
    //           if(e.result.context[i].id.includes('district')) {
    //             this.user.county = e.result.context[i].text;
    //           }
    //           if(e.result.context[i].id.includes('country')) {
    //             this.user.country = e.result.context[i].text;
    //           }
    //         }
    //         // First line of address
    //         if(e.result.text || e.result.address) {
    //           this.user.line_1 = (e.result.address ? e.result.address + ' ' : '') + (e.result.text ? e.result.text : '');
    //         }
    //       }
    //
    //       // Full Address
    //       if(e.result.place_name) {
    //         this.user.full_address = e.result.place_name;
    //       }
    //       // Coordinates
    //       if(e.result.center) {
    //         this.user.longitude = e.result.center[0];
    //         this.user.latitude = e.result.center[1];
    //       }
    //       // Boundary Box
    //       if(e.result.bbox) {
    //         this.user.boundary_box = e.result.bbox;
    //       }
    //     });
    //
    //     // Clear results container when search is cleared.
    //     geocoder.on('clear', () => {
    //       // results.innerText = '';
    //       this.user.postcode = '';
    //       this.user.line_2 = '';
    //       this.user.city = '';
    //       this.user.county = '';
    //       this.user.country = '';
    //       this.user.line_1 = '';
    //       this.user.longitude = '';
    //       this.user.latitude = '';
    //       this.user.boundary_box = '';
    //     });
    //
    //   });
    // }
  },
}
</script>

<style lang="scss">

.info-tooltip {
  position: absolute;
  width: 120px;
  padding: 0 0 0 2px;
  margin-top: -5px;
}
/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  left: 40%;
  top: 20px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

.register .form-select {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.register .form-register {
  padding: 10px 20px 10px 20px;
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}

.register .register-header {
  width: 100%;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(to left, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  h5 {
    color: white;
    border-radius: 20px 20px 0 0;
    padding: .5rem;
    text-transform: uppercase;
    text-shadow: 2px 1px #3c3c3c;
  }
}

.register .card {
  overflow: hidden;
  border: 0 !important;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(4px) !important;
  input {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  textarea {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}

/* Map Box Geocoder */
#geocoder {
  z-index: 1;
}
.mapboxgl-ctrl-geocoder {
  border-radius: .25rem;
  border: 1px solid #ced4da;
  width: 100%;
  box-shadow: none;
  min-width: 100%;
}
/* End Map Box Geocoder */

/* The switch - the box around the slider */
.switch {
  position: relative;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  -moz-block-height: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Start Upload File */
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1em;
  text-align: center;
  padding: 10px 0;
}

.uploading-user-image {
  width: 50%;
  margin: auto;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

/* End Upload File */

</style>